<app-incident-select [values]="(incidents | async) || []" (valuesChange)="updateIncidents($event)"></app-incident-select>

<mat-divider></mat-divider>

<button mat-menu-item (click)="toggleHistory()" title="ALT+H">
  <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('exportSession')" *ngIf="!(zsMapStateService.observeIsHistoryMode() | async)"
    >history</mat-icon
  >
  <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('exportSession')" *ngIf="zsMapStateService.observeIsHistoryMode() | async"
    >brush</mat-icon
  >
  {{ (zsMapStateService.observeIsHistoryMode() | async) ? i18n.get('drawMode') : i18n.get('history') }}
</button>
<mat-divider></mat-divider>

<button mat-menu-item class="tools-menu-item" [matMenuTriggerFor]="languageOptions">
  <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('language')">language</mat-icon>
  {{ i18n.get('language') }}
</button>
<button mat-menu-item class="tools-menu-item" (click)="print()">
  <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('print')">local_printshop</mat-icon>
  {{ i18n.get('print') }}
</button>
<button mat-menu-item class="tools-menu-item" [matMenuTriggerFor]="protocolOptions">
  <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('protocol')">list_alt</mat-icon>
  {{ i18n.get('protocol') }}
</button>
<button mat-menu-item (click)="help()">
  <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('help')">help</mat-icon>
  {{ i18n.get('help') }}
</button>
<mat-divider></mat-divider>
<ng-container *ngIf="session.observeHasWritePermission() | async">
  <button mat-menu-item class="tools-menu-item" [matMenuTriggerFor]="shareOptions">
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('share')">share</mat-icon>
    {{ i18n.get('share') }}
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item class="tools-menu-item" (click)="navigateEvents()">
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('navigateOperations')">logout</mat-icon>
    {{ i18n.get('navigateOperations') }}
  </button>
</ng-container>
<ng-container *ngIf="!(session.observeHasWritePermission() | async)">
  <button mat-menu-item class="tools-menu-item" (click)="session.logout()">
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('logout')">logout</mat-icon>
    {{ i18n.get('logout') }}
  </button>
</ng-container>

<mat-menu #languageOptions="matMenu">
  <button mat-menu-item *ngFor="let locale of locales" (click)="setLocale(locale)" [ngClass]="{ active: locale === session.getLocale() }">
    {{ i18n.get(locale) }}
  </button>
</mat-menu>

<mat-menu #protocolOptions="matMenu">
  <button mat-menu-item class="tools-menu-item" (click)="protocolTable()">
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('protocolTable')">table_chart</mat-icon>
    {{ i18n.get('protocolTable') }}
  </button>
  <button mat-menu-item class="tools-menu-item" (click)="protocolExcelExport()">
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('protocolSaveAsExcel')">save</mat-icon>
    {{ i18n.get('protocolSaveAsExcel') }}
  </button>
</mat-menu>

<mat-menu #shareOptions="matMenu">
  <button mat-menu-item (click)="generateShareLink(false, false)">
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('generateMultiUseShareLink')">share</mat-icon>
    {{ i18n.get('generateMultiUseShareLink') }}
  </button>
  <button mat-menu-item (click)="generateShareLink(true, false)">
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('generateMultiUseShareLink')">share</mat-icon>
    {{ i18n.get('generateMultiUseShareLink') }} (readonly)
  </button>
  <button mat-menu-item (click)="generateShareLink(false, true)">
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('generateSingleUseShareLink')">qr_code</mat-icon>
    {{ i18n.get('generateSingleUseShareLink') }}
  </button>
  <button mat-menu-item (click)="generateShareLink(true, true)">
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('generateSingleUseShareLink')">qr_code</mat-icon>
    {{ i18n.get('generateSingleUseShareLink') }} (readonly)
  </button>
  <button mat-menu-item (click)="showRevokeShareDialog()">
    <mat-icon aria-hidden="false" [attr.aria-label]="i18n.get('revokeAccess')">locked</mat-icon>
    {{ i18n.get('revokeAccess') }}
  </button>
</mat-menu>
