<div class="geocoder">
  <mat-form-field subscriptSizing="dynamic" appearance="outline" class="mat-elevation-z3">
    <input
      matInput
      [placeholder]="i18n.get('findPlace')"
      [matAutocomplete]="auto"
      [(ngModel)]="inputText"
      (ngModelChange)="geoCodeLoad()"
      #searchField
    />
    <mat-icon aria-hidden="false" aria-label="Search" matSuffix>search</mat-icon>
  </mat-form-field>

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="getLabel" (optionSelected)="geoCodeSelected($event)">
    <mat-option
      *ngFor="let location of foundLocations"
      [value]="location.attrs"
      (mouseenter)="previewCoordinate(location.attrs)"
      (mouseleave)="goToCoordinate(false)"
    >
      <span [innerHTML]="location.attrs.label" [title]="getLabel(location.attrs)"></span>
    </mat-option>
  </mat-autocomplete>
</div>
