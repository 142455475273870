<mat-sidenav-container>
  <mat-sidenav class="mat-elevation-z3 noprint" position="end" mode="side" #sidenav [opened]="sidebar.observeIsOpen() | async">
    <div class="sidebar-header">
      <app-credits />
      <button mat-icon-button (click)="sidebar.close()" color="accent" [attr.aria-label]="i18n.get('close')">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    @switch (sidebar.observeContext() | async) {
      @case (SidebarContext.Filters) {
        <app-sidebar-filters></app-sidebar-filters>
      }
      @case (SidebarContext.Layers) {
        <app-sidebar></app-sidebar>
      }
      @case (SidebarContext.History) {
        <app-sidebar-history></app-sidebar-history>
      }
      @case (SidebarContext.Connections) {
        <app-sidebar-connections></app-sidebar-connections>
      }
      @case (SidebarContext.Menu) {
        <app-sidebar-menu></app-sidebar-menu>
      }
      @case (SidebarContext.SelectedFeature) {
        <app-selected-feature></app-selected-feature>
      }
    }
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="main noprint">
      <div class="horizontal-stack">
        <app-geocoder [ngClass]="{ 'sidebar-open': sidebar.observeIsOpen() | async }" />
        <span></span>
      </div>
      <app-coordinates [ngClass]="{ 'sidebar-open': sidebar.observeIsOpen() | async }" />
    </div>
    <div class="action-buttons noprint">
      <div class="vertical-stack">
        <button
          class="sidebar-button mat-elevation-z3"
          mat-icon-button
          [color]="(sidebar.observeContext() | async) === SidebarContext.Menu ? 'primary' : 'basic'"
          (click)="sidebar.toggle(SidebarContext.Menu)"
          [attr.aria-label]="i18n.get('docMapMenuTitle')"
        >
          <mat-icon>menu</mat-icon>
        </button>

        <button
          class="sidebar-button mat-elevation-z3"
          mat-icon-button
          [color]="(sidebar.observeContext() | async) === SidebarContext.Connections ? 'primary' : 'basic'"
          (click)="sidebar.toggle(SidebarContext.Connections)"
          [attr.aria-label]="i18n.get('connections')"
        >
          <mat-icon *ngIf="isOnline | async" [matBadge]="connectionCount | async" matBadgePosition="after">people</mat-icon>
          <mat-icon *ngIf="!(isOnline | async)" [matBadge]="0" matBadgeColor="warn" matBadgePosition="after">cloud_off</mat-icon>
        </button>
        <button
          class="sidebar-button mat-elevation-z3"
          mat-icon-button
          [color]="(sidebar.observeContext() | async) === SidebarContext.Layers ? 'primary' : 'basic'"
          (click)="sidebar.toggle(SidebarContext.Layers)"
          [attr.aria-label]="i18n.get('layers')"
        >
          <mat-icon>layers</mat-icon>
        </button>
        <button
          class="sidebar-button mat-elevation-z3"
          mat-icon-button
          [color]="(sidebar.observeContext() | async) === SidebarContext.Filters ? 'primary' : 'basic'"
          (click)="sidebar.toggle(SidebarContext.Filters)"
          [attr.aria-label]="i18n.get('filters')"
        >
          <mat-icon>filter_alt</mat-icon>
        </button>

        <button
          class="sidebar-button mat-elevation-z3"
          mat-icon-button
          [color]="(sidebar.observeContext() | async) === SidebarContext.History ? 'primary' : 'basic'"
          (click)="sidebar.toggle(SidebarContext.History)"
          [attr.aria-label]="i18n.get('history')"
          *ngIf="isReadOnly | async"
        >
          <mat-icon>history</mat-icon>
        </button>
        <div class="button-toggle mat-elevation-z3">
          <button
            mat-icon-button
            class="toggle-button"
            *ngIf="!(isReadOnly | async)"
            [disabled]="!(canUndo | async)"
            (click)="undo()"
            [attr.aria-label]="i18n.get('undo')"
          >
            <mat-icon>undo</mat-icon>
          </button>
          <mat-divider class="divider"></mat-divider>
          <button
            mat-icon-button
            class="toggle-button"
            *ngIf="!(isReadOnly | async)"
            [disabled]="!(canRedo | async)"
            (click)="redo()"
            [attr.aria-label]="i18n.get('redo')"
          >
            <mat-icon>redo</mat-icon>
          </button>
        </div>
        <div class="button-toggle mat-elevation-z3">
          <button mat-icon-button class="toggle-button" (click)="zoomIn()" [attr.aria-label]="i18n.get('zoomIn')">
            <mat-icon>zoom_in</mat-icon>
          </button>
          <mat-divider class="divider"></mat-divider>
          <button mat-icon-button class="toggle-button" (click)="zoomToScale()" [attr.aria-label]="i18n.get('zoomToScale')">
            <mat-icon>linear_scale</mat-icon>
          </button>
          <mat-divider class="divider"></mat-divider>
          <button mat-icon-button class="toggle-button" (click)="zoomOut()" [attr.aria-label]="i18n.get('zoomOut')">
            <mat-icon>zoom_out</mat-icon>
          </button>
        </div>
      </div>
      <div class="fab-button">
        <button mat-fab color="primary" aria-label="Add" (click)="openDrawDialog()" *ngIf="!(isReadOnly | async)">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
