<form class="login-form">
  <mat-card>
    <mat-card-header class="title">
      <mat-card-title><h1>Login</h1></mat-card-title>
      <img style="border-radius: 10px" mat-card-sm-image src="/assets/img/favicon/zskarte-logo.png" />
    </mat-card-header>

    <mat-card-content>
      <mat-form-field class="full-width" appearance="outline" *ngIf="!isLoginWithCodeEnabled">
        <mat-label>ZSO</mat-label>
        <input
          matInput
          [formControl]="filterControl"
          (input)="filterOrganizations()"
          [value]="selectedOrganization?.name"
          [matAutocomplete]="auto"
          placeholder="Organisation eingeben"
        />
        <mat-icon matSuffix>arrow_drop_down</mat-icon>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="onSelectOrg($event)"
          (closed)="onCloseAutocomplete()"
          autoActiveFirstOption
          [displayWith]="nameProperty"
        >
          <mat-option *ngFor="let organization of filteredOrganizations | async" [value]="organization">
            <div class="option-wrapper">
              <img *ngIf="organization.logoSrc" [src]="organization.logoSrc" [srcset]="organization.logoSrcSet" class="login-logo" />
              {{ organization.name }}
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field class="full-width" appearance="outline" *ngIf="!isLoginWithCodeEnabled">
        <mat-label>Passwort</mat-label>
        <input matInput type="password" placeholder="Passwort eingeben" [(ngModel)]="password" name="password" />
      </mat-form-field>
      <mat-error *ngIf="session.getAuthError() && !isLoginWithCodeEnabled">{{
        session.getAuthError()?.error?.error?.message || session.getAuthError()?.statusText || session.getAuthError()?.message
      }}</mat-error>

      <!-- Login with code dialog-->
      <mat-form-field class="full-width" appearance="outline" *ngIf="isLoginWithCodeEnabled">
        <mat-label>Login-Code</mat-label>
        <input matInput type="text" placeholder="Login-Code eingeben" [(ngModel)]="joinCode" name="joinCode" />
      </mat-form-field>

      <stack [spacing]="5">
        <button class="login-button" mat-raised-button color="primary" (click)="login()">Login</button>
        <button
          class="login-button"
          *ngIf="isLoginWithCodeEnabled"
          mat-raised-button
          color="secondary"
          (click)="isLoginWithCodeEnabled = !isLoginWithCodeEnabled"
        >
          {{ i18n.get('back') }}
        </button>
        <button
          class="alternative-login-button"
          *ngIf="!isLoginWithCodeEnabled"
          mat-raised-button
          (click)="isLoginWithCodeEnabled = !isLoginWithCodeEnabled"
        >
          <span *ngIf="!isLoginWithCodeEnabled">{{ i18n.get('codeLogin') }}</span>
          <span *ngIf="isLoginWithCodeEnabled">{{ i18n.get('cancel') }}</span>
        </button>
        <text-divider *ngIf="!isLoginWithCodeEnabled">{{ i18n.get('or') }}</text-divider>
        <button class="alternative-login-button" *ngIf="!isLoginWithCodeEnabled" mat-raised-button (click)="guestLogin()">
          {{ i18n.get('guestLogin') }}
        </button>
      </stack>
    </mat-card-content>
  </mat-card>
</form>
