<div #mapElement id="map" class="map"></div>
<ng-container *ngIf="mousePosition | async as mousePosition">
  <div
    class="tooltip noprint"
    *ngIf="currentSketchSize | async as sketchSize"
    [style.left.px]="mousePosition[0]"
    [style.top.px]="mousePosition[1]"
    [innerHTML]="sketchSize"
  ></div>
</ng-container>

<button mat-mini-fab class="edit-button" color="warn" #delete>
  <i class="material-icons">delete_forever</i>
</button>
<button mat-mini-fab class="edit-button rotate-button" color="primary" #rotate>
  <i class="material-icons">rotate_left</i>
</button>
<button mat-mini-fab class="edit-button" color="primary" #copy>
  <i class="material-icons">content_copy</i>
</button>

<button mat-mini-fab class="edit-button" color="primary" #draw>
  <mat-icon>stars</mat-icon>
</button>
<button mat-mini-fab class="edit-button" #close>
  <mat-icon>close</mat-icon>
</button>
