<mat-accordion multi displayMode="flat">
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ i18n.get('generalFilters') }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <div class="filterAll">
        <button mat-raised-button (click)="filterAll(false)">
          {{ i18n.get('showAllElements') }}
        </button>
        <button mat-raised-button (click)="filterAll(true)">
          {{ i18n.get('hideAllElements') }}
        </button>
        <mat-slide-toggle [checked]="enableClustering$ | async" (change)="toggleClustering()">
          {{ i18n.get('featureClustering') }}
        </mat-slide-toggle>
      </div>
    </ng-template>
  </mat-expansion-panel>
  <mat-divider></mat-divider>
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ i18n.get('filter') }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div class="filterButtons" *ngIf="filterSymbols && filterSymbols.length > 0">
        <mat-grid-list cols="5" gutterSize="4">
          <mat-grid-tile *ngFor="let symbol of filterSymbols">
            <button mat-button class="filterItem" (click)="toggleSymbolOrFeatureFilter(symbol)">
              <div style="position: relative; height: 100%; display: flex; justify-content: center; align-items: center">
                <img
                  [class.opaque]="symbol.hidden"
                  *ngIf="symbol.src"
                  [src]="symbol.src"
                  [title]="symbol.label"
                  style="max-width: 100%; height: auto"
                />
                <mat-icon [class.opaque]="symbol.hidden" aria-hidden="false" *ngIf="symbol.icon">{{ symbol.icon }}</mat-icon>
                <mat-icon class="eyeOverlay" *ngIf="symbol.hidden">visibility_off</mat-icon>
              </div>
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </ng-template>
  </mat-expansion-panel>
  <mat-divider></mat-divider>
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ i18n.get('categories') }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <mat-selection-list>
        <mat-list-option *ngFor="let category of signCategories" (click)="toggleCategoryFilter(category)" [selected]="!category.isHidden">
          <div class="categoryDot" [style.background-color]="category.color"></div>
          {{ i18n.get('sign' + capitalizeFirstLetter(category.name)) }}
        </mat-list-option>
      </mat-selection-list>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
